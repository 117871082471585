<template>
	<div class="home">
		<jumbotron-banner
			headingMsg="We make games!"
			subheadingMsg="Against all odds, given our horrible sleeping schedule..."
			btnMsg="LEARN ABOUT US"
			:imgURL="require('@/assets/images/title-card-background.jpg')"
			linkURL="/aboutus"/>
		<jumbotron-banner
			headingMsg="Voltage Vendors"
			subheadingMsg="A cosy, retrofuturistic device fixing sim, set within the Milky Way."
			:imgURL="require('@/assets/images/voltage-vendors-key-art.png')"
			linkURL="/voltagevendors"/>
		<jumbotron-banner-mailing-list
			headingMsg="Join our mailing list!"
			subheadingMsg="Want to be kept up to date with all our going ons? Well this is the best way to do it!"/>
	</div>
</template>

<script>
import JumbotronBanner from '@/components/JumbotronBanner.vue'
import JumbotronBannerMailingList from '@/components/JumbotronBannerMailingList.vue'

export default {
	name: 'HomeView',
	components: {
		JumbotronBanner,
		JumbotronBannerMailingList
	}
}
</script>
