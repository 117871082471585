<template>
	<navigation-bar/>
	<router-view/>
</template>

<script>
// @ is an alias to /src
import NavigationBar from '@/components/NavigationBar.vue'

export default {
	name: 'HomeView',
	components: {
		NavigationBar
	}
}
</script>

<style lang="scss">
// Primary Colors
$transparent: rgba(255, 255, 255, 0);

// Main Color
$color-eerie-black: #161825;
$color-eerie-black-transparent: #16182590;

// Generic Gradient Color Palette
$color-purple-taupe: #4E3551;
$color-sugar-plum: #975068;
$color-copper-crayola: #D87869;
$color-rajah: #FCB261;
$color-sunny: #F9F871;

// Matching Gradient Color Palette
$color-onyx: #383447;
$color-black-coral: #5F536B;
$color-mountbatten-pink: #8A738F;
$color-pastel-purple: #BA94B3;
$color-thistle-crayola: #ECB7D5;

// Other Colors
$color-brilliant-azure: #2E8AF8;
$color-deep-saffron: #F89C2E;


// Set Colors
$text-color: #ffffff;
$text-hover-color: $color-deep-saffron; //$color-pastel-purple;

$text-link-color: $color-copper-crayola;
$text-link-hover-color: $color-deep-saffron;

$primary-color: $color-mountbatten-pink;
$secondary-color: $color-purple-taupe;
$accent-color: $color-copper-crayola;
$background-color: $color-eerie-black;

$card-border-color: $color-black-coral;
$card-background-color: $color-onyx;
$card-accent-color: $color-rajah;

$button-primary-color: $color-eerie-black;
$button-border-color: $transparent;
$button-primary-hover-color: $color-deep-saffron;

@font-face {
	font-family: 'futura-regular';
	src: url('~@/assets/fonts/futura-regular.ttf') format('truetype');
}

@font-face {
	font-family: 'ubuntu-regular';
	src: url('~@/assets/fonts/ubuntu-regular.ttf') format('truetype');
}

body {
	font-family: ubuntu-regular !important;
	color: $text-color !important;
	background-color: $background-color !important;
}

a:link {
  color: $text-link-color;
}

a:visited {
  color: $text-link-color;
}

a:hover {
  color: $text-link-hover-color;
}

a:active {
  color: $text-link-hover-color;
}

.card {
	color: $text-color !important;
	background-color: $card-background-color !important;
	border-color: $card-border-color !important;
	border-width: 2px !important;
	h5, h6 {
		color: $card-accent-color !important;
	}
}

.navigation-bar {
	padding-top: 100px !important;
}

.navbar {
	background-color: $background-color !important;
	background-image: none !important;
}

.navbar-brand {
	color: $text-color !important;
}

.nav-link {
	color: $text-color !important;
}

.nav-link:hover {
	color: $text-hover-color !important;
}

.btn-primary {
	background-color: $text-color !important;
	border-color: $transparent !important;
	color: $button-primary-color !important;
}

.btn-primary:hover {
	background-color: $button-primary-hover-color !important;
}

.jumbotron {
	background-size: cover;
	background-position: center;
	padding-top: 80px;
	padding-bottom: 80px;
	min-height: 400px;
}

@media only screen and (min-width: 700px) {
	.jumbotron {
		padding-top: 150px;
		min-height: 600px;
	}
}

@media only screen and (min-width: 1875px) {
	.jumbotron {
		padding-top: 200px;
		min-height: 800px;
	}
}

.top-margin {
	margin-top: 20px !important;
}

.btn-margin {
	margin-top: 30px !important;
	// margin-left: -10px !important;
}

// .jumbotron-text span {
	// 	background-color: $background-color !important;
	// 	box-shadow: 10px 0px $background-color, -10px 0px $background-color;
	// 	box-decoration-break: clone;
	// 	-webkit-box-decoration-break: clone;
	// 	line-height: 48px !important;
	// 	padding-top: 6px !important;
	// 	padding-bottom: 6px !important;
	// }

.jumbotron-transparent-cover {
	box-shadow: inset 0 0 0 1000px $color-eerie-black-transparent;
}

// Text
h1 {
	font-size: 58px !important;
}

h2 {
	font-size: 42px !important;
}

h3 {
	font-size: 38px !important;
}

h4 {
	font-size: 30px !important;
}

h5 {
	font-size: 26px !important;
}

h6 {
	font-size: 22px !important;
}

p, a, ul, il {
	font-size: 18px !important;
}
</style>
